import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import { Button, Modal } from 'antd';
// import $ from 'jquery';

import { WEBSITE_EXTERNAL_LAMBDA_ENDPOINT } from "../../../../common/Constants";

const FormSection = () => {
    const [landingFormData, setLandingFormData] = useState({
        firstName: "",
        email: "",
        source: "Landing Page - CITY SCAPE GLOBAL",
        phone: "",
        dialCode: "+90",
    })
    const [phoneWarn, setPhoneWarn] = useState('');
    const [firstNameWarn, setFirstNameWarn] = useState('');
    const [emailWarn, setEmailWarn] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [countries, setCountries] = useState([]);

    async function fetchCountries() {
        try {
            const res = await axios.get("/assets/data/countryCode.json");
            if (res) {
                // console.log("res.data ", res.data);
                setCountries(res.data);
            }
        } catch (error) {
            console.error("error ", error);
        }
    }

    const resetStateValues = () => {
        setPhoneWarn("");
        setFirstNameWarn("");
        setEmailWarn("");
        setLandingFormData({
            firstName: "",
            email: "",
            phone: "",
            source: "Landing Page - CITY SCAPE GLOBAL",
            dialCode: "+90",
        })
    }
    const onSubmit = (e) => {
        e.preventDefault();
        if (landingFormData.firstName.length === "" || landingFormData.email.length === "" || landingFormData.phone.length < 4) {
            setFirstNameWarn("Please Enter Name")
            setPhoneWarn("Please Enter Phone number")
            setEmailWarn("Please Enter Email id")
            return false;
        }
        setBtnLoading(true);
        axios.post(WEBSITE_EXTERNAL_LAMBDA_ENDPOINT + "become-our-partner/landing-enrollment-request", landingFormData)
            .then((res) => {
                console.log("res", res);
                if (res.status === 200) {
                    setBtnLoading(false);
                    resetStateValues();
                    console.log("dfd", res.data.message);
                    Modal.success({
                        content: (
                            <div>
                                <p>Thanks!</p>
                                <p>We will contact you soon</p>
                            </div>
                        ),
                        okText: "Tamam"
                    });
                }
            })
            .catch((error) => {
            });
    }

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
        fetchCountries();
    }, []);
    return (
        <div className="form_box">
            <div className="container-fluid">
                <div className="text-center">
                    <span>
                        <button>REGISTER NOW</button>
                    </span>
                </div>
                <div className="row justify-content-center">
                    <div className="col-md-4 name_col">
                        <input
                            type="text"
                            placeholder="Name"
                            className="form-control"
                            onChange={(e) => setLandingFormData({
                                ...landingFormData,
                                firstName: e.target.value
                            })}
                            value={landingFormData.firstName}
                        />
                        <div className='validationError'>
                            <p className='errorMsg'>{firstNameWarn}</p>
                        </div>
                    </div>
                    <div className="col-md-4 code_col">
                        <div className="row align-items-center">
                            <div className="col-5 pe-1">
                                <select className="form-control" onChange={(e) => setLandingFormData({
                                    ...landingFormData,
                                    dialCode: e.target.value
                                })}>
                                    {countries.length > 0 && countries.map((row, index) =>
                                        <option value={row.dial_code} key={index}>{row.name}{row.dial_code}</option>)}
                                </select>
                            </div>
                            <div className="col-7 ps-1">
                                <input
                                    type="tel"
                                    id="txtPhone"
                                    className="txtbox form-control"
                                    onChange={(e) => setLandingFormData({
                                        ...landingFormData,
                                        phone: e.target.value.replace(/\D/g, "")
                                    })}
                                    placeholder="Phone No."
                                    value={landingFormData.phone}
                                />
                            </div>
                        </div>
                        <div className='validationError'>
                            <p className='errorMsg'>{phoneWarn}</p>
                        </div>
                    </div>
                    <div className="col-md-4 email_col">
                        <input
                            type="text"
                            placeholder="Email"
                            className="form-control"
                            onChange={(e) => setLandingFormData({
                                ...landingFormData,
                                email: e.target.value
                            })}
                            value={landingFormData.email}
                        />
                        <div className='validationError'>
                            <p className='errorMsg'>{emailWarn}</p>
                        </div>
                    </div>
                    <div className="text-center">
                        <span>
                            <button loading={btnLoading} className="submit_btn" id="btnSubmit" onClick={onSubmit}>SUBMIT</button>
                        </span>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default FormSection;