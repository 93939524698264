import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import SimpleReactValidator from 'simple-react-validator';
import { WEBSITE_EXTERNAL_LAMBDA_ENDPOINT, WEBSITE_URL } from "../../../common/Constants";
import "./alya.scss"

function AlyaMarketingPage() {
    const simpleValidator = useRef(new SimpleReactValidator());
    const [landingFormData, setLandingFormData] = useState({
        // projectInterest: "",
        firstName: "",
        email: "",
        source: "Landing Page - ALYA 4 MEVSIM",
        phone: ""
        // dial_code: "",
    })
    const [phoneWarn, setPhoneWarn] = useState('');
    const [firstNameWarn, setFirstNameWarn] = useState('');
    const [emailWarn, setEmailWarn] = useState('');

    const resetStateValues = () => {
        setPhoneWarn("");
        setFirstNameWarn("");
        setEmailWarn("");
        setLandingFormData({
            firstName: "",
            email: "",
            phone: ""
        })
    }

    const onSubmit = (e) => {
        e.preventDefault();
        if (landingFormData.firstName.length == "" || landingFormData.phone.length < 4 || landingFormData.email.length == "") {
            setFirstNameWarn("please enter valid first name");
            setPhoneWarn("please enter valid phone number");
            setEmailWarn("please enter valid email address");
            return false;
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ 'event': 'alya4MevsimLPEN' });
        axios.post(WEBSITE_EXTERNAL_LAMBDA_ENDPOINT + "become-our-partner/landing-enrollment-request", landingFormData)
            .then((res) => {
                if (res.status === 200) {
                    resetStateValues()
                    $("#exampleModal").modal('show')
                }
            })
            .catch((error) => {
            });
    }

    $(function () {
        setTimeout(
            function () {
                $(document).ready(function () {
                    $("#carouselExampleIndicatorsMain").carousel();
                });
            }, 5000);
    })
    $(function () {
        setTimeout(
            function () {
                $(document).ready(function () {
                    $("#carouselExampleIndicator").carousel();
                    // alert("started");
                });
            }, 5000);
    })

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);

    return (
        <>
            <div className="alyaSection">
                <section className="tema_istanbul_main_section" id="desktop-nav">
                    <article className="main_banner_section" id="01">
                        <div className="main_banner_carousel">
                            <div id="carouselExampleIndicatorsMain" className="carousel slide d-md-block d-none" data-bs-ride="true">
                                <div className="carousel-indicators">
                                    <button type="button" id="btn1" data-bs-target="#carouselExampleIndicatorsMain"
                                        data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" id="btn2" data-bs-target="#carouselExampleIndicatorsMain"
                                        data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" id="btn3" data-bs-target="#carouselExampleIndicatorsMain"
                                        data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" id="btn4" data-bs-target="#carouselExampleIndicatorsMain"
                                        data-bs-slide-to="3" aria-label="Slide 4"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/alya/1.png" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/alya/2.png" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/alya/3.png" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/alya/4.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="carousel_slider d-block d-md-none">
                            <div id="carouselExampleIndicators" className="carousel slide" data-bs-ride="true">
                                <div className="carousel-indicators">
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="0"
                                        className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="1"
                                        aria-label="Slide 2"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="2"
                                        aria-label="Slide 3"></button>
                                    <button type="button" data-bs-target="#carouselExampleIndicators" data-bs-slide-to="3"
                                        aria-label="Slide 4"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/alya/1.png" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/alya/2.png" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/alya/3.png" />
                                    </div>
                                    <div className="carousel-item">
                                        <img className="img-fluid" src="/assets/images/landingpageimage/alya/4.png" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="main_banner_image_section">
                            <div className="container">
                                <div className="position-relative">
                                    <div className="row m-0">
                                        <div className="col text-md-start text-center">
                                            <a href={WEBSITE_URL} target="_blank"> <img className="cubedots_logo_image"
                                                src="/assets/images/landingpageimage/alya/cubedots.png" alt="" /></a>
                                        </div>
                                    </div>
                                    <div className="social_icons">
                                        <p>Follow us</p>
                                        <div className="social_icons_image">
                                            <div>
                                                <a href="https://instagram.com/cubedots?igshid=NDk5N2NlZjQ=" target="_blank">
                                                    <img src="/assets/images/landingpageimage/alya/instagram.png" />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row m-0 justify-content-center">
                                        <div className="col-md-8">
                                            <div className="main_banner_content">
                                                <img src="/assets/images/landingpageimage/alya/alya_logo.png" className="img-fluid" />
                                                <div className="main_banner_content_box_section">
                                                    <small className="small_text">ALYA 4 MEVSIM</small>
                                                    <h1>Experience the Magic of Each Season with Alya 4 Mevsim</h1>
                                                    <p>
                                                        Located in the vibrant district of Beylikdüzü - Esenyurt, Alya 4 Mevsim offers its residents a safe and peaceful ambience surrounded by rich urban amenities. It is the perfect choice for those who value a tranquil lifestyle and seek an upscale living experience in the European side of Istanbul.

                                                    </p>
                                                </div>
                                                <div className="form_box">
                                                    <div className="row justify-content-center">
                                                        <div className="col-md-4 name_col">
                                                            <input type="text" placeholder="Name" className="form-control"
                                                                name="firstName"
                                                                onKeyUp={() => simpleValidator?.current?.showMessageFor("firstName")}
                                                                value={landingFormData?.firstName}
                                                                onChange={(e) => setLandingFormData({
                                                                    ...landingFormData,
                                                                    firstName: e.target.value
                                                                })} />
                                                            <div className='validationError'>
                                                                <p className='errorMsg'>{firstNameWarn}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 code_col">
                                                            <input type="tel" placeholder="Phone Number" id="txtPhone" className="txtbox form-control"
                                                                name="mobile"
                                                                value={landingFormData?.phone}
                                                                onKeyUp={() => simpleValidator?.current?.showMessageFor("mobile")}
                                                                onChange={(e) => setLandingFormData({
                                                                    ...landingFormData,
                                                                    phone: e.target.value
                                                                })} />
                                                            <div className='validationError'>
                                                                <p className='errorMsg'>{phoneWarn}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-4 email_col">
                                                            <input type="text" placeholder="E-mail" className="form-control"
                                                                name="email"
                                                                onKeyUp={() => simpleValidator?.current?.showMessageFor("email")}
                                                                value={landingFormData?.email}
                                                                onChange={(e) => setLandingFormData({
                                                                    ...landingFormData,
                                                                    email: e.target.value
                                                                })} />
                                                            <div className='validationError'>
                                                                <p className='errorMsg'>{emailWarn}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 text-md-center text-end">
                                                            <button className="second_send_btn" id="btnSubmit"
                                                                onClick={onSubmit}>Submit</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <a href="https://goo.gl/maps/rZWzKsVgJYBn9yZQ9" target="_blank"><button className="location_btn">
                                        Location
                                    </button></a>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className="main_content_section" id="02">
                        <div className="container">
                            <div className="row justify-content-center">
                                <div className="col-md-9">
                                    <div className="content_box">
                                        <div className="row">
                                            <div className="col-md-6 order-sm-1 order-2">
                                                <div className="ideal_content">
                                                    <small className="small_text">GET STARTED</small>
                                                    <h1>
                                                        Escape to the Tranquility of Alya's Four Seasons
                                                    </h1>
                                                    <p>The project is built on total land plot area of 6,580 sqm, which includes green landscaping and facilities, with 4 blocks, 217 residential units, and 6 commercial units constructed on an area of 37.675 sqm, featuring a diverse range of apartment types ranging from 1+1 to 3+1.
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="col-md-5 order-sm-2 order-1">
                                                <div className="ideal_image">
                                                    <img src="/assets/images/landingpageimage/alya/content_image.png" className="img-fluid" />
                                                    {/* <a href="https://wa.me/908505327532" target="_blank"><img
                                                            src="images/watsup.png" className="img-fluid" /></a> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="family_friendly_main_section d-md-block d-none">
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="family_friendly_box">
                                                        <img src="/assets/images/landingpageimage/alya/ambiance.png" />
                                                        <h6>Ambience</h6>
                                                        <p>
                                                            Our buildings offer a unique ambience of modern elegance and comfort, perfectly suited for those seeking the ultimate urban living experience. Each unit in our facility has been carefully crafted to provide residents with ample space, natural light, and a serene living environment.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="family_friendly_box">
                                                        <img src="/assets/images/landingpageimage/alya/location.png" />
                                                        <h6>Location</h6>
                                                        <p>
                                                            Alya 4 Mevsim’s close proximity to both TEM and E5 highways makes transportation incredibly easy, along with multiple public transport options. The project is only minutes away from Buyukçekmeçe Lake, The Marmara Sea and Beylikdüzü Yaşam Vadisi Park.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="family_friendly_box">
                                                        <img src="/assets/images/landingpageimage/alya/lifestyle.png" />
                                                        <h6>Lifestyle</h6>
                                                        <p>
                                                            Live life peacefully in the only gated community of its kind that offers affordable, classy living. Enjoy yourself at our on-site facilities featuring a gym, indoor pool, spa and cafe to meet all your needs! Kids can enjoy plenty of fun with access to meeting rooms, playgrounds, and event spaces too — everything you need for an enjoyable lifestyle is here.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="carouselExampleIndicator"
                                            className="family_friendly_main_section d-block d-md-none carousel slide "
                                            data-ride="carousel">
                                            <ol className="carousel-indicators">
                                                <li data-target="#carouselExampleIndicator" data-slide-to="0" className="active"></li>
                                                <li data-target="#carouselExampleIndicator" data-slide-to="1"></li>
                                                <li data-target="#carouselExampleIndicator" data-slide-to="2"></li>
                                            </ol>
                                            <div className="carousel-inner">
                                                <div className="carousel-item active">
                                                    <div className="family_friendly_box">
                                                        <img src="/assets/images/landingpageimage/alya/ambiance.png" />
                                                        <h6>Ambience</h6>
                                                        <p>
                                                            Our buildings offer a unique ambience of modern elegance and comfort, perfectly suited for those seeking the ultimate urban living experience. Each unit in our facility has been carefully crafted to provide residents with ample space, natural light, and a serene living environment.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="carousel-item">
                                                    <div className="family_friendly_box">
                                                        <img src="/assets/images/landingpageimage/alya/location.png" />
                                                        <h6>Location</h6>
                                                        <p>
                                                            Alya 4 Mevsim’s close proximity to both TEM and E5 highways makes transportation incredibly easy, along with multiple public transport options. The project is only minutes away from Buyukçekmeçe Lake, The Marmara Sea and Beylikdüzü Yaşam Vadisi Park.
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="carousel-item">
                                                    <div className="family_friendly_box">
                                                        <img src="/assets/images/landingpageimage/alya/lifestyle.png" />
                                                        <h6>Lifestyle</h6>
                                                        <p>
                                                            Live life peacefully in the only gated community of its kind that offers affordable, classy living. Enjoy yourself at our on-site facilities featuring a gym, indoor pool, spa and cafe to meet all your needs! Kids can enjoy plenty of fun with access to meeting rooms, playgrounds, and event spaces too — everything you need for an enjoyable lifestyle is here.
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className="position-relative ">
                        <div className="image_section_footer1">
                            <div id="carouselExampleIndicatorsImageContent" className="carousel slide d-md-block d-none"
                                data-bs-ride="true">
                                <div className="carousel-indicators">
                                    {/* <h3>Start</h3> */}
                                    <button type="button" id="btn1" data-bs-target="#carouselExampleIndicatorsImageContent"
                                        data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
                                    <button type="button" id="btn2" data-bs-target="#carouselExampleIndicatorsImageContent"
                                        data-bs-slide-to="1" aria-label="Slide 2"></button>
                                    <button type="button" id="btn3" data-bs-target="#carouselExampleIndicatorsImageContent"
                                        data-bs-slide-to="2" aria-label="Slide 3"></button>
                                    <button type="button" id="btn4" data-bs-target="#carouselExampleIndicatorsImageContent"
                                        data-bs-slide-to="3" aria-label="Slide 4"></button>
                                    <button type="button" id="btn5" data-bs-target="#carouselExampleIndicatorsImageContent"
                                        data-bs-slide-to="4" aria-label="Slide 5"></button>
                                </div>
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src="/assets/images/landingpageimage/alya/01.png" className="d-block w-100 img-fluid" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="/assets/images/landingpageimage/alya/02.png" className="d-block w-100 img-fluid" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="/assets/images/landingpageimage/alya/03.png" className="d-block w-100 img-fluid" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="/assets/images/landingpageimage/alya/04.png" className="d-block w-100 img-fluid" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src="/assets/images/landingpageimage/alya/05.png" className="d-block w-100 img-fluid" alt="..." />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="image_section_footer2">
                            <div className="carousel_sliders d-block d-md-none">
                                <div id="carouselExampleIndicatorss" className="carousel slide" data-bs-ride="true">
                                    <div className="carousel-indicators">
                                        <button type="button" data-bs-target="#carouselExampleIndicatorss" data-bs-slide-to="0"
                                            className="active" aria-current="true" aria-label="Slide 1"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicatorss" data-bs-slide-to="1"
                                            aria-label="Slide 2"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicatorss" data-bs-slide-to="2"
                                            aria-label="Slide 3"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicatorss" data-bs-slide-to="3"
                                            aria-label="Slide 4"></button>
                                        <button type="button" data-bs-target="#carouselExampleIndicatorss" data-bs-slide-to="4"
                                            aria-label="Slide 5"></button>
                                    </div>
                                    <div className="carousel-inner">
                                        <div className="carousel-item active">
                                            <img src="/assets/images/landingpageimage/alya/01.png" className="d-block w-100 img-fluid" alt="..." />
                                        </div>
                                        <div className="carousel-item">
                                            <img src="/assets/images/landingpageimage/alya/02.png" className="d-block w-100 img-fluid" alt="..." />
                                        </div>
                                        <div className="carousel-item">
                                            <img src="/assets/images/landingpageimage/alya/03.png" className="d-block w-100 img-fluid" alt="..." />
                                        </div>
                                        <div className="carousel-item">
                                            <img src="/assets/images/landingpageimage/alya/04.png" className="d-block w-100 img-fluid" alt="..." />
                                        </div>
                                        <div className="carousel-item">
                                            <img src="/assets/images/landingpageimage/alya/05.png" className="d-block w-100 img-fluid" alt="..." />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </article>
                    <article className="cubedots_last" id="03">
                        <a href={WEBSITE_URL} target="_blank"><img src="/assets/images/landingpageimage/alya/cubedots.png" /></a>
                    </article>
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog">
                            <div className="modal-content">
                                <p>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" fill="#EA611D"
                                        className="bi bi-check-circle-fill" viewBox="0 0 16 16">
                                        <path
                                            d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
                                    </svg>
                                </p>
                                <div className="modal-body">
                                    <h3>Thank You !</h3>
                                    <button type="button" className="okBtn" data-bs-dismiss="modal">Ok</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </>
    )
}
export default AlyaMarketingPage;