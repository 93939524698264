import React, { useState, useEffect, useRef } from "react";

import axios from 'axios';
import Modal from 'react-modal';
import { Spinner } from "react-bootstrap";
import SimpleReactValidator from 'simple-react-validator';
import DatePicker from "react-date-picker";
import { message } from "antd";
import moment from "moment";

import { useApp } from "../hooks/AppHooks";
import SuccessfulPopupComponent from "./SuccessfulPopupComponent";
import { insiderEvent, CMS_API_ENDPOINT, WEBSITE_X_API_KEY, WEBSITE_EXTERNAL_LAMBDA_ENDPOINT, AGENT_PORTAL_URL } from "../common/Constants";
import { useLocation } from "react-router-dom";


function EnrollmentModalComponent(props) {
    const { unsoldProjectValue, enquireValue, enrollmentValue, countries, language } = useApp();
    const publicPath = process.env.PUBLIC_URL;
    const simpleValidator = useRef(new SimpleReactValidator());
    const location = useLocation();

    const [projects, setProjects] = useState([]);
    const [showInput, setShowInput] = useState('');
    const [warnemail, setwarnemail] = useState("");
    const [lastname, setLastName] = useState("");
    const [cityError, setCityError] = useState("");
    const [emailerror, setEmailError] = useState("");
    const [toDayDate, setToDayDate] = useState(new Date());
    const [mobileerror, setMobileError] = useState("")
    const [projectinterest, setProjectInterest] = useState("");
    const [countryselect, setCountrySelect] = useState("");
    const [occupationslected, setOccupationSelected] = useState("");
    const [project, setProject] = useState(null);
    const [isSuccessModalShow, setIsSuccessModalShow] = useState(false);
    const [agentSuccessMessage, setAgentSuccessMessage] = useState("");
    const [thanksSuccessMessage, setThanksSuccessMessage] = useState("");
    const [enrollmentData, setEnrollmentData] = useState([]);
    const [dropDown, setDropDown] = useState([]);
    const [enquireForData, setEnquireForData] = useState([]);
    const [enquireForDropdown, setEnquireForDropDown] = useState([]);
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        projectInterest: "",
        firstName: "",
        lastName: "",
        email: "",
        city: "",
        birthdayDate: toDayDate,
        country: "",
        dial_code: "",
        mobile: "",
        occupation: "",
        message: "",
        securityCode: "",
        terms: false,
        event: "",
        iysCall: true,
        iysSms: true,
        iysWhatsapp: true,
        iysEmail: true,
    });
    const [securityCode, setSecurityCode] = useState(null);

    const languageId = location.search?.split('=')[1];

    const HandleInputHide = (event) => {
        const setUserInput = event.target.value;
        setShowInput(setUserInput);
    }

    useEffect(() => {
        if (enrollmentValue && enrollmentValue.length) {
            setProject(props.project);
            genRandomString();
            getEnrollmentData();
            setLoading(false);
            fetchProjects();
            getEnquireForData();
        }
    }, [props.project, unsoldProjectValue, enquireValue, enrollmentValue]);

    useEffect(() => {
        if (toDayDate) {
            setFormData({ ...formData, birthdayDate: moment(toDayDate).utc().format('YYYY/MM/DD') });
        }
    }, [toDayDate]);

    async function fetchProjects() {
        if (unsoldProjectValue && unsoldProjectValue.length) {
            setProjects(unsoldProjectValue ? unsoldProjectValue?.map((data) => ({
                "value": data.id,
                "label": data.title
            })) : null)
        }
    }

    const getEnrollmentData = async () => {
        if (enrollmentValue && enrollmentValue.length) {
            setEnrollmentData(enrollmentValue ? enrollmentValue[0] : null)
            setDropDown(enrollmentValue ? enrollmentValue[0]?.interestedAsDropdownValues?.map((data) => ({
                "value": data.content,
                "label": data.content
            })) : null)
        }
    }

    const getEnquireForData = async () => {
        if (enquireValue && enquireValue.length) {
            setEnquireForData(enquireValue ? enquireValue[0] : null)
            setEnquireForDropDown(enquireValue ? enquireValue[0]?.interestedAsDropdownValues?.map((data) => ({
                "value": data.content,
                "label": data.content
            })) : null)
        }
    }

    const handleChangeCountry = (e) => {
        e.persist();
        let selectedCountryName = e.target.value;
        setFormData((formData) => ({ ...formData, country: selectedCountryName }));
        let countriesList = countries;
        let newDialCode = Object.keys(countriesList)
            .filter((x) => {
                return countriesList[x].country_name === selectedCountryName;
            })
            .map((x) => {
                return countriesList[x].dial_code;
            });
        let dial_code = newDialCode.length ? newDialCode[0] : "";
        setFormData((formData) => ({ ...formData, dial_code: dial_code, event: props.insiderEventName }));
    };


    const handleChangeTerms = (status) => {
        setFormData((formData) => ({ ...formData, terms: status }));
    };

    const genRandomString = () => {
        let text = "";
        const length = 6;
        const possible =
            "ABCDEFGHJKMNOPQRSTUVWXYZabcdefghjkmnopqrstuvwxyz0123456789";
        for (let i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        setSecurityCode(text);
        return text;
    };

    const resetFrom = () => {
        setToDayDate(new Date());
        setFormData({
            projectInterest: "",
            firstName: "",
            lastName: "",
            email: "",
            city: "",
            birthdayDate: toDayDate,
            country: "",
            dial_code: "",
            mobile: "",
            occupation: "",
            message: "",
            securityCode: "",
            terms: false,
            iysCall: true,
            iysSms: true,
            iysWhatsapp: true,
            iysEmail: true,
            event: props.insiderEventName,
        });
        document.getElementById("form1").reset();
    };

    const resetStateValues = () => {
        setwarnemail("");
        setLastName("");
        setCityError("");
        setEmailError("");
        setToDayDate(new Date());
        setMobileError("");
        setProjectInterest("");
        setOccupationSelected("");
        setCountrySelect("");
        setLoading(false);
        genRandomString();
        resetFrom();
        setShowInput('');
        props.onRequestClose();
    }

    const enrollmentRequest = async (e) => {
        await axios
            .post(WEBSITE_EXTERNAL_LAMBDA_ENDPOINT + "become-our-partner/enrollment-request", formData)
            .then((res) => {
                if (res.status === 200) {
                    resetStateValues();
                    // resetFrom();
                    setToDayDate(new Date());
                    setTimeout(() => {
                        setFormData({
                            projectInterest: "",
                            firstName: "",
                            lastName: "",
                            email: "",
                            country: "",
                            city: "",
                            birthdayDate: toDayDate,
                            dial_code: "",
                            mobile: "",
                            occupation: "",
                            message: "",
                            securityCode: "",
                            terms: false,
                            event: props.insiderEventName,
                            iysCall: true,
                            iysSms: true,
                            iysWhatsapp: true,
                            iysEmail: true,
                        });
                        // document.body.style.overflow="hidden"
                        props.onRequestClose()
                        setIsSuccessModalShow(true);
                        setProject(props.project);
                    }, 1000);

                    if (process.env.REACT_APP_ENVIRONMENT === "production" || process.env.REACT_APP_ENVIRONMENT === "staging") {
                        window.dataLayer = window.dataLayer || [];
                        window.dataLayer.push({ 'event': props.event });
                        insiderEvent(props.insiderEventName);
                    }
                }
            })
            .catch((error) => {
                if (error) {
                    if (error.response.status === 422) {
                        let errors = error.response.data.errors;
                        let msg = error.response.data.message + "\n";
                        Object.keys(errors).map(
                            (error, index) => (msg += errors[error][0] + "\n")
                        );
                        alert(msg);
                    }
                }
            });
        setLoading(false);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if (formData.firstName.length == "" || formData.lastName.length == "" || formData.email.length == "" ||
            formData.occupation.length == "" || formData.country.length == "" || formData.mobile.length == "" ||
            (!project && formData.city.length == "" || formData.birthdayDate.length == "") ||
            (formData.dial_code === "+90" && formData.mobile.length !== 10) || formData.mobile.length < 1 || formData.mobile.length > 10) {
            setwarnemail(project ? enquireForData.firstNameValidation : enrollmentData.firstNameValidation);
            setLastName(project ? enquireForData.lastNameValidation : enrollmentData.lastNameValidation);
            setCityError(enrollmentData.cityValidation);
            setEmailError(project ? enquireForData.emailValidation : enrollmentData.emailValidation);
            setMobileError(project ? enquireForData.mobileNumberValidation : enrollmentData.mobileNumberValidation);
            setOccupationSelected(project ? enquireForData.interestedAsValidation : enrollmentData.interestedAsValidation)
            setCountrySelect(project ? enquireForData.countryValidation : enrollmentData.countryValidation);
            return false;
        }
        // if (formData.occupation === dropDown[0]?.value && formData.projectInterest === "" && !props.project) {
        //     setProjectInterest(enrollmentData?.projectValidation);
        //     return false;
        // }

        if (formData.securityCode !== securityCode) {
            { project ? alert(enquireForData.alertMessageSecurityCode) : alert(enrollmentData.alertMessageSecurityCode) }
            return false;
        }

        if (formData.terms === false) {
            { project ? alert(enquireForData.alertMessageTermsAndCondition) : alert(enrollmentData.alertMessageTermsAndCondition) }
            return false;
        }

        if (project) {
            formData.projectInterest = project;
        }

        if (!simpleValidator.current.fields.email) {
            return false;
        }

        setLoading(true);
        if (!project && showInput === dropDown[0]?.value) {
            const postData = {
                name: formData.firstName + " " + formData.lastName,
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                country: formData.country,
                phone: formData.dial_code + formData.mobile,
                source: "Website",
                city: formData.city,
                birthdayDate: moment(formData.birthdayDate).format("YYYY-MM-DD"),
                iysCall: formData.iysCall,
                iysWhatsapp: formData.iysWhatsapp,
                iysSms: formData.iysSms,
                iysMail: formData.iysEmail,
            }
            await axios
                .post(CMS_API_ENDPOINT + "user/add", postData, { headers: { 'x-api-key': WEBSITE_X_API_KEY } })
                .then(async (res) => {
                    if (res.data.status === "Success") {
                        resetStateValues();
                        setToDayDate(new Date());
                        setTimeout(() => {
                            setFormData({
                                projectInterest: "",
                                firstName: "",
                                lastName: "",
                                email: "",
                                country: "",
                                city: "",
                                birthdayDate: toDayDate,
                                dial_code: "",
                                mobile: "",
                                occupation: "",
                                message: "",
                                securityCode: "",
                                terms: false,
                                event: props.insiderEventName,
                                iysCall: true,
                                iysSms: true,
                                iysWhatsapp: true,
                                iysEmail: true,
                            });
                            props.onRequestClose()
                            setIsSuccessModalShow(true);
                            setProject(props.project);
                        }, 1000);
                        setAgentSuccessMessage(enrollmentData.submitMessage[0].AgentSuccessMessage);
                        setThanksSuccessMessage(enrollmentData.submitMessage[0].SuccessContentSecond);
                        await axios
                            .post(CMS_API_ENDPOINT + "user/user-gdpr", postData, { headers: { 'x-api-key': WEBSITE_X_API_KEY } });
                    } else if (res.data.status === "Failure") {
                        message.error(<p dangerouslySetInnerHTML={{ __html: enrollmentData.submitMessage[0].AgentErrorMessage }}></p>, [4]);
                    }
                })
                .catch((error) => console.log("error :", error));
        } else {
            await enrollmentRequest();
        }
        setLoading(false);

    };
    return (
        <>
            {enrollmentValue && enrollmentValue.length && unsoldProjectValue && unsoldProjectValue.length || (enquireValue && enquireValue.length) ?
                <div>
                    <Modal
                        {...props}
                        id="enrollmentModal"
                        onRequestClose={resetStateValues}
                    // onAfterOpen={afterOpenModal}
                    // style={{ overflow: 'hidden' }}
                    // contentLabel="Example Modal"
                    // className={"background-color: #26262662"}
                    >
                        {enrollmentData ?
                            <div className="p-3 enrollmentModal">
                                <div className="row align-items-center">
                                    <div className="col-md-12">
                                        <div className="enrollmentContent">
                                            <div className="enrollHeadingSection">
                                                {project ? <h3> {enquireForData.heading} <strong>{project}</strong></h3> : <h3><span className="underLineClass">{enrollmentData.heading}</span></h3>}
                                                <a className="cloneBtnNewModal" onClick={props.onRequestClose}><img src={publicPath + "/assets/images/Close.png"} onClick={resetStateValues} /></a>
                                            </div>
                                            <form id="form1" onSubmit={onSubmit}>
                                                <div className="row">
                                                    <div className="col-md-6 mb-3">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder={project ? enquireForData.firstNamePlaceholder : enrollmentData.firstNamePlaceholder}
                                                            name="firstName"
                                                            onKeyUp={() => simpleValidator.current.showMessageFor("firstName")}
                                                            value={formData.firstName}
                                                            onChange={(e) =>
                                                                setFormData({
                                                                    ...formData,
                                                                    firstName: e.target.value.replace(/[^a-z]/gi, ''),
                                                                })}
                                                        />
                                                        <div className="text-danger formErrorMsg">
                                                            {simpleValidator.current.message(
                                                                "firstName",
                                                                formData.firstName,
                                                                "alpha_space"
                                                            )}
                                                        </div>
                                                        <div className='validationError'>
                                                            <p className='errorMsg'>{formData.firstName?.length == "" ? warnemail : ""}</p>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 mb-3">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder={project ? enquireForData.lastNamePlaceholder : enrollmentData.lastNamePlaceholder}
                                                            name="lastName"
                                                            onKeyUp={() =>
                                                                simpleValidator.current.showMessageFor("lastName")}
                                                            value={formData.lastName}
                                                            onChange={(e) => setFormData({
                                                                ...formData,
                                                                lastName: e.target.value.replace(/[^a-z]/gi, ''),
                                                            })}
                                                        />
                                                        <div className="text-danger formErrorMsg">
                                                            {simpleValidator.current.message(
                                                                "lastName",
                                                                formData.lastName,
                                                                "alpha_space"
                                                            )}
                                                        </div>
                                                        <div className='validationError'>
                                                            <p className='errorMsg'>{formData.lastName?.length == "" ? lastname : ""}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col">
                                                        <input
                                                            className="form-control"
                                                            type="text"
                                                            placeholder={project ? enquireForData.emailAddressPlaceholder : enrollmentData.emailAddressPlaceholder}
                                                            name="email"
                                                            onKeyUp={() => simpleValidator.current.showMessageFor("email")}
                                                            value={formData.email}
                                                            onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                                                        />
                                                        <div className='validationError'>
                                                            <p className='errorMsg'>{formData.email?.length == "" ? emailerror : ""}</p>
                                                            <div className='errorMsg'>{simpleValidator.current.message('email', formData.email, 'email', {
                                                                messages: {
                                                                    required: emailerror,
                                                                    email: project ? enquireForData.emailValidation : enrollmentData.emailValidation
                                                                }
                                                            })}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col mb-3" onChange={(e) => {
                                                        !props.project ? HandleInputHide(e) : null
                                                    }}>
                                                        <select className="form-select" placeholder="Occupation" name="occupation"
                                                            onChange={(e) => setFormData({
                                                                ...formData,
                                                                occupation: e.target.value,
                                                            })}
                                                            defaultValue={formData.occupation}>
                                                            <option value="" disabled selected hidden> {project ? enquireForData.interestedAsPlaceholder : enrollmentData.interestedAsPlaceholder}</option>
                                                            {project ?
                                                                enquireForDropdown?.map((data) => {
                                                                    return (
                                                                        <option value={data.value}>{data.label}</option>)
                                                                }) :

                                                                dropDown?.map((data) => {
                                                                    return (
                                                                        <option value={data.value}>{data.label}</option>)
                                                                })}
                                                        </select>
                                                        <div className='validationError'>
                                                            <p className='errorMsg'>{formData.occupation === "" ? occupationslected : ""}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        project ? null :
                                                            <div className="col-md-6 mb-3">
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder={enrollmentData.cityPlaceholder}
                                                                    name="city"
                                                                    onKeyUp={() => simpleValidator.current.showMessageFor("city")}
                                                                    value={formData.city}
                                                                    onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                                                                />
                                                                <div className='validationError'>
                                                                    <p className='errorMsg'>{!formData.city?.length ? cityError : ""}</p>
                                                                </div>
                                                            </div>
                                                    }
                                                    <div className="col-md-6 mb-3">
                                                        {countries.length > 0 ? (
                                                            <>
                                                                <select
                                                                    className="form-select scroller"
                                                                    placeholder="Country"
                                                                    name="country"
                                                                    onChange={handleChangeCountry}
                                                                    defaultValue={formData.country_name}>
                                                                    <option value="" disabled selected hidden> {project ? enquireForData.countryPlaceholder : enrollmentData.countryPlaceholder}
                                                                    </option>
                                                                    {countries.length > 0 ?
                                                                        countries.map((row, index) => (
                                                                            <option value={row.country_name} key={index}>
                                                                                {row.country_name}
                                                                            </option>
                                                                        )) : null}
                                                                </select>
                                                            </>
                                                        ) : null}
                                                        <div className='validationError'>
                                                            <p className='errorMsg'>{formData.country.length == "" ? countryselect : ""}</p>
                                                        </div>
                                                    </div>
                                                    {
                                                        project && <div className="col-md-6  mb-3">
                                                            <div className="input-group">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    {formData.dial_code ? formData.dial_code :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                                            className="bi bi-telephone-plus" viewBox="0 0 16 16">
                                                                            <path
                                                                                d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                                            <path fillRule="evenodd"
                                                                                d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z" />
                                                                        </svg>}
                                                                </span>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder={project ? enquireForData.mobileNumberPlaceholder : enrollmentData.mobileNumberPlaceholder}
                                                                    name="mobile"
                                                                    value={formData.mobile}
                                                                    onKeyUp={() => simpleValidator.current.showMessageFor("mobile")}
                                                                    onChange={(e) => setFormData({
                                                                        ...formData,
                                                                        mobile: e.target.value.replace(/\D/g, "")
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="text-danger formErrorMsg"></div>
                                                            <div className='validationError'>
                                                                <p className='errorMsg'>{formData.mobile.length == "" || formData.mobile.length !== 10 ? mobileerror : ""}</p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    {
                                                        project ? null :
                                                            <div className="col-md-6 mb-3">
                                                                <DatePicker
                                                                    yearPlaceholder={'yyyy'}
                                                                    monthPlaceholder={'mm'}
                                                                    dayPlaceholder={'dd'}
                                                                    maxDate={new Date()}
                                                                    className="form-control"
                                                                    placeholder={enrollmentData.dobPlaceholder}
                                                                    name="birthdayDate"
                                                                    value={toDayDate} onChange={setToDayDate} format="dd/MM/yyyy" />
                                                                <div className='validationError'>
                                                                    <p className='errorMsg'>{!toDayDate ? enrollmentData.dobValidation : ""}</p>
                                                                </div>
                                                            </div>
                                                    }
                                                    {
                                                        !project && <div className="col-md-6  mb-3">
                                                            <div className="input-group">
                                                                <span className="input-group-text" id="basic-addon1">
                                                                    {formData.dial_code ? formData.dial_code :
                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                                                            className="bi bi-telephone-plus" viewBox="0 0 16 16">
                                                                            <path
                                                                                d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511z" />
                                                                            <path fillRule="evenodd"
                                                                                d="M12.5 1a.5.5 0 0 1 .5.5V3h1.5a.5.5 0 0 1 0 1H13v1.5a.5.5 0 0 1-1 0V4h-1.5a.5.5 0 0 1 0-1H12V1.5a.5.5 0 0 1 .5-.5z" />
                                                                        </svg>}
                                                                </span>
                                                                <input
                                                                    className="form-control"
                                                                    type="text"
                                                                    placeholder={project ? enquireForData.mobileNumberPlaceholder : enrollmentData.mobileNumberPlaceholder}
                                                                    name="mobile"
                                                                    value={formData.mobile}
                                                                    onKeyUp={() => simpleValidator.current.showMessageFor("mobile")}
                                                                    onChange={(e) => setFormData({
                                                                        ...formData,
                                                                        mobile: e.target.value.replace(/\D/g, "")
                                                                    })}
                                                                />
                                                            </div>
                                                            <div className="text-danger formErrorMsg"></div>
                                                            <div className='validationError'>
                                                            <p className='errorMsg'>{
                                                                formData.mobile.length == "" ||
                                                                    (formData.dial_code === "+90" && formData.mobile.length !== 10) ||
                                                                    formData.mobile.length > 10 ? mobileerror : ""
                                                            }</p>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col mb-3">
                                                        <textarea
                                                            className="form-control"
                                                            placeholder={project ? enquireForData.messagePlaceholder : enrollmentData.messagePlaceholder}
                                                            name="message"
                                                            defaultValue={formData.message}
                                                            onChange={(e) => setFormData({ ...formData, message: e.target.value })}>
                                                        </textarea>
                                                    </div>
                                                </div>
                                                {/* {
                                                    showInput === dropDown[0]?.value ?
                                                        <div className="row">
                                                            <div className="col mb-3">
                                                                {projects.length > 0 ? (
                                                                    <>
                                                                        <select
                                                                            className="contactComponent form-select"
                                                                            placeholder={enrollmentData.projectPlaceholder}
                                                                            name="country"
                                                                            onChange={(e) => {
                                                                                setFormData({
                                                                                    ...formData,
                                                                                    projectInterest: e.target.value,
                                                                                })
                                                                            }} defaultValue={formData.projectInterest}
                                                                        >
                                                                            <option value="" disabled selected hidden> {enrollmentData.projectPlaceholder}
                                                                            </option>
                                                                            {projects.length > 0 ?
                                                                                projects.map((row, index) => (
                                                                                    <option
                                                                                        value={row.value}
                                                                                        key={index}> {row.label}
                                                                                    </option>
                                                                                )) : null}
                                                                        </select>
                                                                        <div className='validationError'>
                                                                            <p className='errorMsg'>{formData.projectInterest.length == "" ? projectinterest : ""}</p>
                                                                        </div>
                                                                    </>
                                                                ) : null}
                                                            </div>
                                                        </div>
                                                        : null
                                                } */}
                                                <div className="row">
                                                    <div className="captchInput">
                                                        <input
                                                            type="text"
                                                            placeholder={project ? enquireForData.securityCode : enrollmentData.securityCode}
                                                            name="securityCode"
                                                            autoComplete="off"
                                                            defaultValue={formData.securityCode}
                                                            onChange={(e) => setFormData({
                                                                ...formData,
                                                                securityCode: e.target.value,
                                                            })} />
                                                        <span className="captha_code mr-sm-2 captcha-code-disable" id="notcp">
                                                            {securityCode}
                                                        </span>
                                                        <button
                                                            type="button"
                                                            onClick={() => genRandomString()}
                                                            className="btn btn-default">
                                                            <svg
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                width="16"
                                                                height="16"
                                                                fill="currentColor"
                                                                className="bi bi-arrow-repeat"
                                                                viewBox="0 0 16 16">
                                                                <path
                                                                    d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                                                <path
                                                                    fillRule="evenodd"
                                                                    d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                                            </svg>
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    !project &&
                                                    <div style={{ marginBottom: "5px" }}>
                                                        <div className="termsAndConditionSection" style={{ marginBottom: "10px" }}>
                                                            <small className="formFooterContent">
                                                                {enrollmentData.iysDetails && enrollmentData.iysDetails[0].iysConfirmStatement}
                                                            </small>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-check termAndConditionCheckbox">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={formData.iysCall}
                                                                        onClick={() => setFormData({ ...formData, iysCall: !formData.iysCall })} />
                                                                    <label className="form-check-label">
                                                                        <div className="termsAndConditionSection">
                                                                            <small className="formFooterContent">
                                                                                {enrollmentData.iysDetails && enrollmentData.iysDetails[0].iysPhone}
                                                                            </small>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-check termAndConditionCheckbox">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={formData.iysSms}
                                                                        onClick={() => setFormData({ ...formData, iysSms: !formData.iysSms })} />
                                                                    <label className="form-check-label">
                                                                        <div className="termsAndConditionSection">
                                                                            <small className="formFooterContent">
                                                                                {enrollmentData.iysDetails && enrollmentData.iysDetails[0].iysSms}
                                                                            </small>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div className="form-check termAndConditionCheckbox">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={formData.iysEmail}
                                                                        onClick={() => setFormData({ ...formData, iysEmail: !formData.iysEmail })} />
                                                                    <label className="form-check-label">
                                                                        <div className="termsAndConditionSection">
                                                                            <small className="formFooterContent">
                                                                                {enrollmentData.iysDetails && enrollmentData.iysDetails[0].iysEmail}
                                                                            </small>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                            <div className="col">
                                                                <div className="form-check termAndConditionCheckbox">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        checked={formData.iysWhatsapp}
                                                                        onClick={() => setFormData({ ...formData, iysWhatsapp: !formData.iysWhatsapp })} />
                                                                    <label className="form-check-label">
                                                                        <div className="termsAndConditionSection">
                                                                            <small className="formFooterContent">
                                                                                {enrollmentData.iysDetails && enrollmentData.iysDetails[0].iysWhatsapp}
                                                                            </small>
                                                                        </div>
                                                                    </label>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="form-check termAndConditionCheckbox">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                id="flexCheckDefault"
                                                                defaultValue={formData.terms}
                                                                onClick={() => handleChangeTerms(!formData.terms)} />
                                                            <label className="form-check-label">
                                                                <div className="termsAndConditionSection">
                                                                    <small className="formFooterContent" dangerouslySetInnerHTML={{ __html: `${project ? enquireForData.termsAndConditionMessage : enrollmentData.termsAndConditionMessage}` }}>
                                                                    </small>
                                                                </div>
                                                            </label>
                                                        </div>
                                                        <div className="text-center mt-1">
                                                            {loading ? (
                                                                <button className="btntheme" type="button" disabled>
                                                                    <Spinner
                                                                        as="span"
                                                                        animation="border"
                                                                        size="sm"
                                                                        role="status"
                                                                        aria-hidden="true" />
                                                                    {" "}
                                                                    {project ? enquireForData.submitButton : enrollmentData.submitButton}
                                                                </button>
                                                            ) : (
                                                                <button className="btntheme" type="submit">
                                                                    {project ? enquireForData.buttonName : enrollmentData.buttonName}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </form>
                                            <div>
                                                <div className="socialIconsDiv">
                                                    <div className="row socialIconsCompo">
                                                        <ul>
                                                            <small className="socialIconsEnroll">
                                                                <a target="_blank" href="https://wa.me/908505327532">
                                                                    <img src={publicPath + "/assets/images/newsdetailicon/whatspp.png"} />
                                                                </a>
                                                            </small>
                                                            <small className="socialIconsEnroll">
                                                                <a target="_blank" href="https://instagram.com/cubedots?igshid=NDk5N2NlZjQ=">
                                                                    <img src={publicPath + "/assets/images/newsdetailicon/instagram.png"} />
                                                                </a>
                                                            </small>
                                                            <small className="socialIconsEnroll">
                                                                <a target="_blank" href="https://www.facebook.com/cubedots">
                                                                    <img src={publicPath + "/assets/images/newsdetailicon/facebook.png"} />
                                                                </a>
                                                            </small>
                                                            <small className="socialIconsEnroll">
                                                                <a target="_blank" href="https://www.linkedin.com/company/cubedots/mycompany/?viewAsMember=true">
                                                                    <img src={publicPath + "/assets/images/newsdetailicon/linkedin.png"} />
                                                                </a>
                                                            </small>
                                                            <small className="socialIconsEnroll">
                                                                <a target="_blank" href="https://www.youtube.com/cubedots">
                                                                    <img src={publicPath + "/assets/images/newsdetailicon/Youtube.png"} />
                                                                </a>
                                                            </small>
                                                            <small className="socialIconsEnroll">
                                                                <a target="_blank" href="https://mobile.twitter.com/cubedots">
                                                                    <img src={publicPath + "/assets/images/newsdetailicon/Twitter.png"} />
                                                                </a>
                                                            </small>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div >
                            </div >
                            : null
                        }
                    </Modal >
                    {
                        isSuccessModalShow ?
                            <SuccessfulPopupComponent
                                agentSuccessMessage={agentSuccessMessage}
                                thanksSuccessMessage={thanksSuccessMessage}
                                setAgentSuccessMessage={setAgentSuccessMessage}
                                setThanksSuccessMessage={setThanksSuccessMessage}
                                isModalShow={isSuccessModalShow}
                                onClose={() => setIsSuccessModalShow(false)}
                                submitMessageData={project ? enquireForData : enrollmentData}
                            />
                            : null
                    }
                </div >
                : null
            }
        </>
    )
}

export default EnrollmentModalComponent;