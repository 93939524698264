export function youtube_parser(url) {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
}

export function insiderEvent(eventName) {
    Insider.track('events', [{
        "event_name": eventName,
        "event_params": {}
    }]);
    window.insider_object = window.insider_object || {};
    window.insider_object.page = {
        "type": "Confirmation"
    }
}

export const capitalizeFirst = (str) => {
    return str && str?.charAt(0).toUpperCase() + str?.slice(1);
};

// API ENDPOINT not need anymore @todo Hanu - need to remove
export const API_ENDPOINT = "https://portal.cubedots.com/api/v1/";
export const WEBSITE_EXTERNAL_LAMBDA_ENDPOINT = process.env.REACT_APP_AWS_WEBSITE_EXTERNAL_LAMBDA;
export const AGENT_PORTAL_URL = process.env.REACT_APP_AGENT_PORTAL_URL;
export const WEBSITE_URL = process.env.REACT_APP_WEBSITE_URL;
export const CMS_API_ENDPOINT = `${process.env.REACT_APP_AWS_API_GATEWAY_END_POINT}`;
export const CMS_PORTAL_BUCKET = `${process.env.REACT_APP_AWS_NAMESPACE}-cms-portal`;
export const WEBSITE_X_API_KEY = process.env.REACT_APP_WEBSITE_X_API_KEY;